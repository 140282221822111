<template>
  <div></div>
</template>
<script>
import NewsCard from "./NewsCard.vue";

import newsService from "~/service/newsService";

export default {
  name: "NewsAlert",
  data() {
    return {
      newsList: [],
      showDialog: false,
      timeout: null
    };
  },
  props: { news: { type: Object, required: false } },
  methods: {
    async reload() {
      let newsList = (await newsService.feed()) || [];
      for (let i = 0; i < newsList.length; i++) {
        let news = newsList[i];
        let res = await this.$dialog.show(NewsCard, {
          waitForResult: true,
          news: news,
          showMarkAsRead: true
        });
        if (res) {
          await newsService.markAsRead(news.newsId);
        }
      }
      this.timeout = setTimeout(() => {
        this.reload();
      }, 600000);
    },
    async openNewsFromProps() {
      await this.$dialog.show(NewsCard, {
        waitForResult: true,
        news: this.news,
        showMarkAsRead: true
      });
      return this.$emit("closeNewsPopup", true);
    }
  },
  mounted() {
    if (this.news) {
      this.openNewsFromProps();
    } else {
      setTimeout(this.reload, 6000);
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  }
};
</script>
