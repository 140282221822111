<template>
  <div class="news-list-container">
    <CategoryTitle :category="category" />
    <template v-if="newsList && newsList.length > 0">
      <v-row>
        <v-col
          cols="12"
          md="6"
          xl="4"
          v-for="news in newsList"
          :key="news.id"
          @click.stop.prevent="openNews(news)"
        >
          <news-card
            :key="news.newsId"
            :news="news"
            :show-opened="true"
            :show-read="false"
            :blockHandleLink="true"
            @read="markAsRead(news.newsId)"
          />
        </v-col>
      </v-row>
      <v-pagination
        v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
        :value="page"
        :page="pager.selPage"
        :length="pager.totPages ? pager.totPages : 0"
        :totalVisible="6"
        @next.stop.prevent="handlePageFilter"
        @prev="handlePageFilter"
        @input="handlePageFilter"
      ></v-pagination>

      <NewsAlert
        v-if="openedNews != null"
        :news="openedNews"
        @closeNewsPopup="closeNewsPopup"
      />
    </template>
    <div v-else-if="!loading" class="news-list-empty">
      {{ $t("") }}
    </div>
  </div>
</template>
<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import NewsCard from "@/components/news/NewsCard.vue";
import NewsAlert from "@/components/news/NewsAlert.vue";

import category from "~/mixins/category";
import newsService from "~/service/newsService";

export default {
  name: "NewsList",
  mixins: [category],
  components: {
    CategoryTitle,
    NewsCard,
    NewsAlert
  },
  data() {
    return {
      loading: true,
      newsList: [],
      openedNews: null,
      pager: {},
      page: 1
    };
  },
  computed: {
    showDialog() {
      return this.newsList.length > 0;
    }
  },
  methods: {
    async fetchNews() {
      try {
        this.newsList = await newsService.list(true);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    openNews(id) {
      let clone = JSON.parse(JSON.stringify(id));
      this.openedNews = clone;
    },
    closeNewsPopup() {
      this.openedNews = null;
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.fetchNews(this.page, 24);
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  mounted() {
    this.fetchNews();
  }
};
</script>
